import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Features</AnchorLink>
  <AnchorLinks small mdxType="AnchorLinks">
    <AnchorLink mdxType="AnchorLink">Deployment Profiles</AnchorLink>
    <AnchorLink mdxType="AnchorLink">Stations Deployments</AnchorLink>
    <AnchorLink mdxType="AnchorLink">Other</AnchorLink>
  </AnchorLinks>
    </AnchorLinks>
    <InlineNotification mdxType="InlineNotification">
  To see images/gifs/vidoes below, please connect to VPN
    </InlineNotification>
    <h2>{`Features`}</h2>
    <h3>{`Deployment Profiles`}</h3>
    <ul>
      <li parentName="ul">{`New Product filter for workflows in Deployment Profile Creation dialog
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2023.1.16%252FProductFilterInProfileCreationDialog.png&isNativeBrowsing=false",
          "alt": "Workflow Filter by Product"
        }}></img></li>
    </ul>
    <h3>{`Stations Deployments`}</h3>
    <h4>{`Wave Execution`}</h4>
    <ul>
      <li parentName="ul">{`Image base URL parameter value is now displayed to users in Wave Execution views
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2023.1.16%252FBaseImageUrlParameterValueBox.png&isNativeBrowsing=false",
          "alt": "Image Base URL Parameter Value Box"
        }}></img></li>
    </ul>
    <ul>
      <li parentName="ul">{`Image base URL parameter value is inherited from the parent (facility for which SoldTo ID and ShipTo ID are the same) if not set on a faciliy level`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`Updated Wave Execution Overview UI with progress summary and most common problems across all waves and per wave
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2023.1.16%252FWaveExecutionOverview.png&isNativeBrowsing=false",
          "alt": "Wave Execution Overview"
        }}></img></li>
    </ul>
    <ul>
      <li parentName="ul">{`AIO-6 support`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`Station Upgrade step that switches the station to boot from the new image now doesn’t complete until data sync step is complete`}</li>
    </ul>
    <h3>{`Other`}</h3>
    <ul>
      <li parentName="ul">{`‘Customers’ UI that lists all facilities is renamed to ‘Facilities’. Facilities are grouped by Customer by default.
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2023.1.16%252FFacilitiesGroupedByCustomer.png&isNativeBrowsing=false",
          "alt": "Facilites Grouped by Customer"
        }}></img></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      